<template>
    <div class="content">
        <div class="search_box">
            <Input v-model="storePrams.keyword" style="width:200px" placeholder="请输入姓名/身份证"></Input>
            <Button @click="searchBtn" style="color:#FFF;background:#34B8FF;margin-left:10px">搜索</Button>
        </div>
        <Table :columns="columns12" :data="data6">
            <template slot-scope="{ index }" slot="number">
                <div>{{index+1}}</div>
            </template>
            <template slot-scope="{ row }" slot="sex">
                <div v-if="row.sex==1">男</div>
                <div v-if="row.sex==2">女</div>
            </template>
            <template slot-scope="{ row }" slot="action">
                <Button @click="addBtn(row)" type="primary" ghost size="small" style="margin-right: 10px;color:#34B8FF;border-color:#34B8FF">修改</Button>
            </template>
        </Table>
        <br/>
        <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="pageChange" :page-size="storePrams.limit"/>
        
        <!-- 修改弹框 -->
        <Modal v-model="billModal">
            <p slot="header" style="font-weight:600;font-size:18px">修改</p>
            <Form style="padding:0 30px" label-position="left" :label-width="80">
                <FormItem label="名称">
                    <Input v-model="formData.name" placeholder="请输入名称"></Input>
                </FormItem>
                <FormItem label="性别">
                    <Select v-model="formData.sex">
                        <Option v-for="item in sexList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem label="年龄">
                    <Input v-model="formData.age" placeholder="请输入年龄"></Input>
                </FormItem>
                <FormItem label="身份证号">
                    <Input v-model="formData.identity_card" placeholder="请输入身份证号"></Input>
                </FormItem>
                <FormItem label="地址">
                    <Input v-model="formData.address" placeholder="请输入地址"></Input>
                </FormItem>
            </Form>
            <div slot="footer">
                <Button @click="billBtn" size="large" style="background:#38B6FB;color:#FFF;border-radius:10px">确认</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import { userList,updateUserInfo } from "@/api/index.js";
    export default {
        data(){
            return{
                total:0,
                storePrams:{
                    page:1,
                    limit:10,
                    keyword:''
                },
                columns12: [
                    {
                        title: '序号',
                        slot: 'number',
                        width: 80,
                        align: 'center'
                    },
                    {
                        title: '姓名',
                        key: 'name',
                        align: 'center'
                    },
                    {
                        title: '性别',
                        slot: 'sex',
                        align: 'center'
                    },
                    {
                        title: '年龄',
                        key: 'age',
                        align: 'center'
                    },
                    {
                        title: '身份证',
                        key: 'identity_card',
                        align: 'center'
                    },
                    {
                        title: '地址',
                        key: 'address',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        slot: 'action',
                        width: 150,
                        align: 'center'
                    }
                ],
                data6: [],
                formData:{
                    id:null,
                    name:'',
                    age:'',
                    sex:'',
                    identity_card:'',
                    address:''
                },
                billModal:false,
                sexList: [
                    {
                        value: 1,
                        label: '男'
                    },
                    {
                        value: 2,
                        label: '女'
                    }
                ]
            }
        },
        created(){
            this.userList()
        },
        methods:{
            userList(){
                userList(this.storePrams).then(res=>{
                    this.total = res.data.total
                    this.data6 = res.data.data
                })
            },
            searchBtn(){
                this.userList()
            },
            addBtn(e){
                this.formData.id = e.id
                this.formData.name = e.name
                this.formData.age = e.age
                this.formData.sex = e.sex
                this.formData.identity_card = e.identity_card
                this.formData.address = e.address
                this.billModal = true
            },
            billBtn(){
                updateUserInfo(this.formData).then(res=>{
                    this.$Message.success(res.msg)
                    this.billModal = false
                    this.userList()
                })
            },
            pageChange(e){
                this.storePrams.page = e
                this.userList()
            }
        }
    }
</script>

<style scoped>
    .content{
        width: 100%;
        height: calc(100vh - 104px);
        overflow: auto;
        padding: 20px 40px;
        background: #FFF;
        border-radius: 8px;
        box-shadow: #F4F0E8 0px 1px 2px 0px;
    }
    .search_box{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .inoculation_box{
        max-height: 500px;
        overflow: auto;
    }
    .table_nav{
        background: #D6F1FF;
        display: flex;
        align-items: center;
        height: 60px;
        font-size: 16px;
        color: #445E87;
    }
    .item_th{
        display: flex;
        align-items: center;
        height: 50px;
        font-size: 16px;
        color: #445E87;
    }
    .wep50{
        width: 50%;
        text-align: center;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
    }
</style>
<style>
    .ivu-table-header thead tr th{
        background: #AEE3FF;
    }
    .ivu-table td, .ivu-table th {
        border-bottom: 1px solid #F0F6FC;
        /* color: #74798C; */
    }
    .ivu-table:before{
        background: #F0F6FC;
    }
    .ivu-modal-footer , .ivu-modal-header{
        border: none;
    }
    .ivu-form .ivu-form-item-label{
        color: #445E87;
        font-size: 16px;
    }
    .ivu-input{
        border-radius: 10px;
    }
    .ivu-select-single .ivu-select-selection{
        border-radius: 10px;
    }
</style>